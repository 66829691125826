export const FORM_DATE_HEADER = { 'Content-Type': 'multipart/form-data' };

export const MAX_PER_PAGE = 10000;
export const DEFAULT_PAGE_SIZE = 15;
export const UPDATED_SUCCESSFULLY_TEXT = '更新成功';
export const CREATED_SUCCESSFULLY_TEXT = '新增成功';
export const DELETED_SUCCESSFULLY_TEXT = '刪除成功';
export const VERIFY_SUCCESSFULLY_TEXT = '審核通過';
export const VERIFY_FAILED_TEXT = '審核失敗';
export const INCLUDE_SUCCESSFULLY_TEXT = '入選成功';
export const EXCLUDE_SUCCESSFULLY_TEXT = '淘汰成功';
export const VERIFYING_SUCCESSFULLY_TEXT = '回待審核成功';
