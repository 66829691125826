import { request } from './utils';

import {
  DailyCampaignStatisticsResponse,
  ExportExcelResponse,
  GetDailyCampaignStatisticsRequestOptions
} from './interfaces';

export function getDailyCampaignStatistics({ query }: GetDailyCampaignStatisticsRequestOptions): Promise<DailyCampaignStatisticsResponse> {
  return request({
    url: '/analytics/campaigns/daily',
    method: 'get',
    params: query
  });
}

export function exportDailyCampaignStatistics({ query }: GetDailyCampaignStatisticsRequestOptions): Promise<ExportExcelResponse> {
  return request({
    url: '/analytics/campaigns/daily/export',
    method: 'get',
    params: query,
    responseType: 'blob'
  });
}
