import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const channelRouter: Array<RouteRecordRaw> = [
  {
    path: '/channels',
    component: shallowRef(Layout),
    // redirect: 'noRedirect',
    meta: {
      title: 'channels',
      icon: '#icon-channel'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "channel-list" */ '@/views/channels/List.vue'),
        name: 'list_channels',
        meta: {
          title: 'channels',
          icon: '#icon-channel'
        }
      },
      {
        path: 'show/:id(\\d+)',
        component: () => import(/* webpackChunkName: "channel-show" */ '@/views/channels/Show.vue'),
        name: 'show_channelCampaigns',
        meta: {
          title: 'channelCampaigns',
          noCache: true,
          activeMenu: '/channels',
          hidden: true
        }
      }
    ]
  }
];

export default channelRouter;
