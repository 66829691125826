import { request } from './utils';

import {
  PlansResponse,
  GetPlansRequestOptions
} from './interfaces';

export function getPlans({ query }: GetPlansRequestOptions): Promise<PlansResponse> {
  return request({
    url: '/plans',
    method: 'get',
    params: query
  });
}
