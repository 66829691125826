import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const userRouter: Array<RouteRecordRaw> = [
  {
    path: '/users',
    component: shallowRef(Layout),
    // redirect: 'noRedirect',
    meta: {
      title: 'users',
      icon: '#icon-user',
      hidden: true
    },
    children: [
      {
        path: 'edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "user-edit" */ '@/views/users/Edit.vue'),
        name: 'edit_user',
        meta: {
          title: 'editUser',
          noCache: true,
          activeMenu: '/users',
          hidden: true
        }
      },
      {
        path: '',
        component: () => import(/* webpackChunkName: "user-list" */ '@/views/users/List.vue'),
        name: 'list_users',
        meta: {
          title: 'users',
          icon: '#icon-user'
        }
      },
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "work-create" */ '@/views/users/Create.vue'),
        name: 'create_user',
        meta: {
          title: 'createUser',
          activeMenu: '/users',
          hidden: true
        }
      },
      {
        path: 'show/:id(\\d+)',
        component: () => import(/* webpackChunkName: "user-show" */ '@/views/users/Show.vue'),
        name: 'show_user',
        meta: {
          title: 'showUser',
          noCache: true,
          activeMenu: '/users',
          hidden: true
        }
      },
      {
        path: 'review-users',
        component: () => import(/* webpackChunkName: "user-show" */ '@/views/review-users/List.vue'),
        name: 'list_review-user',
        meta: {
          title: 'reviewUser',
          noCache: true,
          activeMenu: 'review-users',
          // TODO: need to change the icon
          icon: '#icon-user'
        }
      }
    ]
  }
];

export default userRouter;
