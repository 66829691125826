import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const permissionRouter: Array<RouteRecordRaw> = [
  {
    path: '/permissions',
    component: shallowRef(Layout),
    redirect: '/permissions/admins',
    meta: {
      title: 'permissions',
      icon: '#icon-permission',
      hidden: true
    },
    children: [
      {
        path: 'admins',
        component: () => import(/* webpackChunkName: "admin-list" */ '@/views/admins/List.vue'),
        name: 'list_admins',
        meta: {
          title: 'admins',
          icon: '#icon-admin'
        }
      },
      {
        path: 'admins/create',
        component: () => import(/* webpackChunkName: "admin-create" */ '@/views/admins/Create.vue'),
        name: 'create_admin',
        meta: {
          title: 'createAdmin',
          activeMenu: '/admins',
          hidden: true
        }
      },
      {
        path: 'admins/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "admin-edit" */ '@/views/admins/Edit.vue'),
        name: 'edit_admin',
        meta: {
          title: 'editAdmin',
          noCache: true,
          activeMenu: '/admins',
          hidden: true
        }
      },
      {
        path: 'roles',
        component: () => import(/* webpackChunkName: "role-list" */ '@/views/roles/List.vue'),
        name: 'list_roles',
        meta: {
          title: 'roles',
          icon: '#icon-rolepermission'
        }
      },
      {
        path: 'roles/create',
        component: () => import(/* webpackChunkName: "role-create" */ '@/views/roles/Create.vue'),
        name: 'create_role',
        meta: {
          title: 'createRole',
          activeMenu: '/roles',
          hidden: true
        }
      },
      {
        path: 'roles/edit/:id(\\d+)',
        component: () => import(/* webpackChunkName: "role-edit" */ '@/views/roles/Edit.vue'),
        name: 'edit_role',
        meta: {
          title: 'editRole',
          noCache: true,
          activeMenu: '/roles',
          hidden: true
        }
      }
    ]
  }
];

export default permissionRouter;
