import { request } from './utils';

import {
  CampaignCommissionsResponse,
  ChannelCampaignsResponse,
  ChannelsResponse,
  GetCampaignCommissionsRequestOptions,
  GetChannelCampaignsRequestOptions,
  GetChannelsRequestOptions
} from './interfaces';

export function getChannelCampaigns({ query, channelId }: GetChannelCampaignsRequestOptions): Promise<ChannelCampaignsResponse> {
  return request({
    url: `/channels/${channelId}/campaigns`,
    method: 'get',
    params: query
  });
}

export function getChannels({ query }: GetChannelsRequestOptions = {}): Promise<ChannelsResponse> {
  return request({
    url: '/channels',
    method: 'get',
    params: query
  });
}

export function getCampaignCommissions({ query }: GetCampaignCommissionsRequestOptions = {}): Promise<CampaignCommissionsResponse> {
  return request({
    url: '/campaigns/commissions',
    method: 'get',
    params: query
  });
}

export function GetCampaignCommissionOverview({ query }: GetCampaignCommissionsRequestOptions = {}): Promise<CampaignCommissionsResponse> {
  return request({
    url: '/campaigns/commissions/overview',
    method: 'get',
    params: query
  });
}
