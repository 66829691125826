import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const withdrawalRouter: Array<RouteRecordRaw> = [
  {
    path: '/withdrawals',
    component: shallowRef(Layout),
    // redirect: 'noRedirect',
    meta: {
      title: 'withdrawals',
      icon: '#icon-wallet'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "withdrawal-list" */ '@/views/withdrawals/List.vue'),
        name: 'list_withdrawals',
        meta: {
          title: 'withdrawals',
          icon: '#icon-wallet'
        }
      },
      {
        path: 'create',
        component: () => import(/* webpackChunkName: "withdrawal-create" */ '@/views/withdrawals/Create.vue'),
        name: 'create_withdrawal',
        meta: {
          title: 'createWithdrawal',
          activeMenu: '/withdrawals',
          hidden: true
        }
      }
    ]
  }
];

export default withdrawalRouter;
