import { request } from './utils';

import {
  UserResponse,
  UsersResponse,
  GetUserRequestOptions,
  GetUsersRequestOptions,
  UpdateUserRequestOptions,
  CreateUserRequestOptions,
  DeleteUserRequestOptions,
  SucceededResponse
} from './interfaces';

export function getUser({ userId }: GetUserRequestOptions): Promise<UserResponse> {
  return request({
    url: `/users/${userId}`,
    method: 'get'
  });
}

export function getUsers({ query }: GetUsersRequestOptions): Promise<UsersResponse> {
  return request({
    url: '/users',
    method: 'get',
    params: query
  });
}

export function createUser({ data }: CreateUserRequestOptions): Promise<UserResponse> {
  return request({
    url: '/users',
    method: 'post',
    data
  });
}

export function updateUser({ userId, data }: UpdateUserRequestOptions): Promise<UserResponse> {
  return request({
    url: `/users/${userId}`,
    method: 'put',
    data
  });
}

export function deleteUser({ userId }: DeleteUserRequestOptions): Promise<SucceededResponse> {
  return request({
    url: `/users/${userId}`,
    method: 'delete'
  });
}
