import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const analyticRouter: Array<RouteRecordRaw> = [
  {
    path: '/analytics',
    component: shallowRef(Layout),
    // redirect: 'noRedirect',
    meta: {
      title: 'analytics',
      icon: '#icon-analysis'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "analytic-list" */ '@/views/analytics/List.vue'),
        name: 'list_analytics',
        meta: {
          title: 'analytics',
          icon: '#icon-analysis'
        }
      }
    ]
  }
];

export default analyticRouter;
