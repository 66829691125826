import { shallowRef } from 'vue';
import { RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const payeeRouter: Array<RouteRecordRaw> = [
  {
    path: '/payee',
    component: shallowRef(Layout),
    // redirect: 'noRedirect',
    meta: {
      title: 'payee',
      icon: '#icon-bank-info'
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "payee-list" */ '@/views/payee/List.vue'),
        name: 'show_payee',
        meta: {
          title: 'payee',
          icon: '#icon-bank-info'
        }
      },
      {
        path: 'edit',
        component: () => import(/* webpackChunkName: "payee-edit" */ '@/views/payee/Edit.vue'),
        name: 'edit_payee',
        meta: {
          title: 'editPayee',
          noCache: true,
          activeMenu: '/payee',
          hidden: true
        }
      }
    ]
  }
];

export default payeeRouter;
