/**
 * Route name 取名規則
 * create_{name}
 * edit_{name}
 * list_{name}s
 * show_{name} (show 的顯示邏輯為有 list 時就會有 show)
 * 若 name 有兩個字以上，operation 以底線連接、resource 以中線連接，如："list_feature-works"
 * 在 permission/actions.ts 會判斷是否有權限，因此命名規則需依照上方邏輯寫
 */

import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Layout from '@/layout/Index.vue';

const constantFiles = require.context('./constantModules', true, /\.ts$/);
let constantModules: Array<RouteRecordRaw> = [];
constantFiles.keys().forEach((key) => {
  if (key === './index.ts') return;
  constantModules = constantModules.concat(constantFiles(key).default);
});

const asyncFiles = require.context('./permissionModules', true, /\.ts$/);
let permissionModules: Array<RouteRecordRaw> = [];
asyncFiles.keys().forEach((key) => {
  if (key === './index.ts') return;
  permissionModules = permissionModules.concat(asyncFiles(key).default);
});

export const constantRoutes: Array<RouteRecordRaw> = [
  {
    path: '/redirect',
    component: Layout,
    meta: { hidden: true },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import(/* webpackChunkName: "redirect" */ '@/views/redirect/Index.vue')
      }
    ]
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Index.vue'),
        name: 'Dashboard',
        meta: {
          title: 'dashboard',
          icon: '#icon-dashboard',
          affix: true
        }
      }
    ]
  },
  ...constantModules
];

export const asyncRoutes: Array<RouteRecordRaw> = [
  ...permissionModules
];

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
});

export function resetRouter() {
  const newRouter = router;
  (router as any).matcher = (newRouter as any).matcher; // reset router
}

export default router;
