import { request } from './utils';

import {
  PayeeResponse,
  UpdatePayeeRequestOptions,
  SucceededResponse
} from './interfaces';

export function getPayee(): Promise<PayeeResponse> {
  return request({
    url: '/payee',
    method: 'get'
  });
}

export function updatePayee({ data }: UpdatePayeeRequestOptions): Promise<SucceededResponse> {
  return request({
    url: '/payee',
    method: 'put',
    data
  });
}
