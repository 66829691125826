/* eslint-disable @typescript-eslint/camelcase */
/*
 * @Description:
 * @Author: ZY
 * @Date: 2020-12-28 17:18:24
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-20 15:18:01
 */

export default {
  route: {
    dashboard: '首頁',
    channels: '代理渠道',
    channelCampaigns: '渠道詳情',
    commissions: '分潤紀錄',
    withdrawals: '代理錢包',
    createWithdrawal: '申請提領',
    payee: '收款資料',
    editPayee: '編輯收款資料',
    analytics: '渠道流量統計'
  },
  navbar: {
    dashboard: '首頁',
    logOut: '登出'
  },
  login: {
    logIn: '登入',
    username: '帳號',
    password: '密碼'
  },
  workStatus: {
    draft: '草稿',
    reviewing: '審核中',
    processing: '處理中',
    published: '上架',
    rejected: '駁回',
    expired: '到期',
    taken_down: '下架'
  },
  withdrawalStatus: {
    auditing: '待批核',
    audited: '已批核',
    success: '支出成功',
    failed: '支出失敗'
  },
  advertisementStatus: {
    published: '在線',
    expired: '過期'
  },
  couponStatus: {
    scheduling: '未上架',
    run_out: '兌換完畢',
    published: '上架',
    expired: '過期',
    taken_down: '下架'
  },
  selectOption: {
    yesterday: '昨日',
    last3days: '近3天',
    last7days: '近7天'
  },
  orderStatus: {
    unpaid: '尚未付款',
    expired: '訂單逾期',
    fail: '付款失敗',
    success: '付款成功'
  },
  orderPaymentMethod: {
    credit_card: '信用卡'
  },
  subscriptionSource: {
    plan: '會員訂閱',
    coupon: '優惠券',
    testing: '後台建立',
    nft: '開通NFT會員'
  },
  actorGender: {
    female: '女',
    male: '男'
  },
  topicType: {
    custom: '自訂',
    exclusive: '麻豆獨家',
    billboard: '前十大熱榜',
    recommend: '專屬推薦',
    newest: '最新上映',
    watch: '觀看紀錄',
    favorite: '最愛',
    coming_soon: '即將上映'
  },
  topicStyle: {
    medium: '小圖',
    large: '大圖',
    billboard: '排行',
    image_text: '圖文'
  },
  adminStatus: {
    enabled: '啟用',
    disabled: '禁用'
  }
};
