/* eslint-disable @typescript-eslint/camelcase */
import { ActionTree, ActionContext } from 'vuex';
import { singular } from 'pluralize';

import { RootState } from '@/store';
import { PermissionState } from './state';
import { Mutations } from './mutations';
import { PermissionMutationType } from './mutation-types';
import { PermissionActionType } from './action-types';
import { asyncRoutes } from '@/router';
import { RouteRecordRaw } from 'vue-router';

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
      key: K,
      payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>
} & Omit<ActionContext<PermissionState, RootState>, 'commit'>

export interface Actions {
  [PermissionActionType.ACTION_SET_ROUTES](
    { commit }: AugmentedActionContext,
    permissions: string[]
  ): void
}

const hasPermission = (
  route: RouteRecordRaw,
  permissionNames: string[]
) => {
  const routeName = route.name as string;

  return !!permissionNames.find((permissionName) => {
    const permissionNameArray = permissionName.split('_');

    const operation = permissionNameArray[0];
    const resource = singular(permissionNameArray[1]);

    if (route.redirect) {
      const childrenRouteName = [];
      // console.log('route.redirect', route);
      route.children.forEach((route) => {
        childrenRouteName.push(route);
      });

      return childrenRouteName.some((children) => {
        return children.name === permissionName;
      });
    }

    if (routeName) {
      // when you can see list page you'll see show page as well
      if (routeName.startsWith('show')) {
        return operation === 'list' && routeName.includes(resource);
      }

      // for batch operation
      if (routeName.startsWith('batch')) {
        const routeOperation = routeName.split('_')[1];
        return operation === routeOperation && routeName.includes(resource);
      }

      return permissionName === routeName;
    }

    return route.path.includes(resource);
  });
};

export const filterRoutes = (
  routes: RouteRecordRaw[],
  permissionNames: string[]
) => {
  const resultRoutes: RouteRecordRaw[] = [];

  routes.forEach(route => {
    if (process.env.NODE_ENV === 'mock' || hasPermission(route, permissionNames)) {
      if (route.children) {
        route.children = filterRoutes(route.children, permissionNames);
      }

      resultRoutes.push(route);
    }
  });

  return resultRoutes;
};

export const actions: ActionTree<PermissionState, RootState> & Actions = {
  [PermissionActionType.ACTION_SET_ROUTES](
    { commit }: AugmentedActionContext,
    permissions: string[]
  ) {
    // console.log('allRoutes', { allRoutes: asyncRoutes });
    // console.log({ permissions });

    // const accessibleRoutes = filterRoutes(
    //   asyncRoutes,
    //   permissions
    // );

    // console.log(accessibleRoutes, { accessibleRoutes });

    commit(PermissionMutationType.SET_ROUTES, asyncRoutes);
  }
};
