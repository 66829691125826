/* eslint-disable @typescript-eslint/camelcase */
import { ActionTree, ActionContext } from 'vuex';
import { RootState, useStore } from '@/store';
import { state, UserState } from './state';
import { Mutations } from './mutations';
import { UserMutationTypes } from './mutation-types';
import { UserActionTypes } from './action-types';
// import { userInfoRequest } from '@/apis/user';
import {
  setPermissions,
  removePermissions
} from '@/utils/local-storage';
import { removeToken, setToken } from '@/utils/cookies';
import { PermissionActionType } from '../permission/action-types';
import router, { resetRouter } from '@/router';
import { RouteRecordRaw } from 'vue-router';

import {
  getPermissions,
  totpLogin,
  TOTPLoginAuthRequestData
} from '@/apis';
import { union } from 'lodash';

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1],
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<UserState, RootState>, 'commit'>

export interface Actions {
  [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    authInfo: TOTPLoginAuthRequestData
  ): Promise<void>
  [UserActionTypes.ACTION_RESET_TOKEN](
    { commit }: AugmentedActionContext
  ): void
  // [UserActionTypes.ACTION_GET_USER_INFO](
  //   { commit }: AugmentedActionContext
  // ): Promise<void>
  [UserActionTypes.ACTION_CHANGE_ROLES](
    { commit }: AugmentedActionContext, role: string
  ): void
  [UserActionTypes.ACTION_LOGOUT](
    { commit }: AugmentedActionContext,
  ): void
}

export const actions: ActionTree<UserState, RootState> & Actions = {
  async [UserActionTypes.ACTION_LOGIN](
    { commit }: AugmentedActionContext,
    { username, code, secret }: TOTPLoginAuthRequestData
  ) {
    const { data } = await totpLogin({
      data: {
        username: username.trim(),
        code,
        secret
      }
    });

    const { token, roles } = data;
    if (!token) return;

    // set token
    setToken(token);
    commit(UserMutationTypes.SET_TOKEN, token);

    // let permissions = [];
    // roles.forEach((role) => {
    //   permissions = union(permissions, role.permissions);
    // });

    // set permissions
    // setPermissions(permissions);
  },

  [UserActionTypes.ACTION_RESET_TOKEN](
    { commit }: AugmentedActionContext) {
    removeToken();
    commit(UserMutationTypes.SET_TOKEN, '');

    removePermissions();
    commit(UserMutationTypes.SET_ROLES, []);
  },

  // async [UserActionTypes.ACTION_GET_USER_INFO](
  //   { commit }: AugmentedActionContext
  // ) {
  //   if (state.token === '') {
  //     throw Error('token is undefined!');
  //   }
  //   await userInfoRequest().then((res) => {
  //     if (res?.code === 0) {
  //       commit(UserMutationTypes.SET_ID, res.data.id);
  //       commit(UserMutationTypes.SET_USERNAME, res.data.username);
  //       commit(UserMutationTypes.SET_NAME, res.data.name);
  //       commit(UserMutationTypes.SET_PHONE, res.data.phone);
  //       commit(UserMutationTypes.SET_STATUS, res.data.status);
  //       commit(UserMutationTypes.SET_ROLES, res.data.roles);
  //       commit(UserMutationTypes.SET_TOKEN, res.data.token);
  //       commit(UserMutationTypes.SET_CREATED_AT, res.data.createdAt);
  //       return res;
  //     } else {
  //       throw Error('驗證失敗，請再重新登入。');
  //     }
  //   });

  //   // TODO
  //   commit(UserMutationTypes.SET_ROLES, undefined);
  //   commit(UserMutationTypes.SET_NAME, res.data.name);
  //   commit(UserMutationTypes.SET_AVATAR, res.data.avatar);
  //   commit(UserMutationTypes.SET_INTRODUCTION, res.data.introduction);
  //   commit(UserMutationTypes.SET_EMAIL, res.data.email);
  // },

  async [UserActionTypes.ACTION_CHANGE_ROLES](
    { commit }: AugmentedActionContext,
    role: string
  ) {
    const token = role + '-token';
    const store = useStore();
    commit(UserMutationTypes.SET_TOKEN, token);
    setToken(token);
    // await store.dispatch(UserActionTypes.ACTION_GET_USER_INFO, undefined);
    store.dispatch(PermissionActionType.ACTION_SET_ROUTES, undefined);
    store.state.permission.dynamicRoutes.forEach((item: RouteRecordRaw) => {
      router.addRoute(item);
    });
  },

  [UserActionTypes.ACTION_LOGOUT](
    { commit }: AugmentedActionContext
  ) {
    // clear token
    removeToken();
    commit(UserMutationTypes.SET_TOKEN, '');

    // clear permissions
    removePermissions();
    commit(UserMutationTypes.SET_ROLES, []);

    resetRouter();
  }
};
