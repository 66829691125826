import { AxiosError } from 'axios';
import {
  PartialUser,
  AdminStatus,
  PartialRole,
  Permission,
  Admin,
  Role,
  User,
  VerifiedType,
  UserReview,
  PartialVerityUserReview,
  VerifiedStatus,
  Channel,
  Campaign,
  CampaignCommission,
  Withdrawal,
  PartialWithdrawal,
  WithdrawalOverview,
  Payee,
  PartialPayee,
  DailyCampaign,
  Plan
} from '@/interfaces';

// #region Base
export interface RequestOptions<TQuery = any, TData = any> {
  query?: TQuery
  data?: TData
}

export interface Response<TData = any> {
  data?: TData
  meta?: {
    currentPage: number
    lastPage: number
    pageSize: number
    total: number
  }
}

export interface ErrorData {
  error?: {
    code: number
    message: string
  }
}

export type ResponseError = Partial<AxiosError<ErrorData>>;
export type SucceededResponse = Response;
export type ExportExcelResponse = Response<BinaryType>;

export interface BaseQuery {
  page?: number
  pageSize?: number
}

export enum IntBoolean {
  TRUE = 1,
  FALSE = 0
}

export enum SortOrder {
  ASCENDING = 'asc',
  DESCENDING = 'desc'
}

export type StringNumber = string

// #endregion

// #region Login

// login auth request
export interface AuthRequestData {
  username: string
  password: string
}
export type LoginRequestData = AuthRequestData
export type LoginRequestOptions = RequestOptions<any, LoginRequestData>

// login auth response
export type LoginResponse = Response<{
  username: string
  qrCode: string
  secret: string
}>

// TOTP login auth request
export interface TOTPLoginAuthRequestData {
  secret: string
  code: string
  username: string
}
export type TOTPLoginRequestData = TOTPLoginAuthRequestData
export type TOTPLoginRequestOptions = RequestOptions<any, TOTPLoginRequestData>

// TOTP login auth response
export type TOTPLoginResponse = Response<{
  id: number
  username: string
  name: string
  phone: string
  status: AdminStatus
  roles: Role[]
  token: string
  createdAt: string
}>

// Logout
export type LogoutResponse = SucceededResponse

// #endregion

// #region User
export enum UserSortKey {
  CREATED_AT = 'createdAt',
  SUBSCRIPTION_ENDED_AT = 'subscriptionEndedAt'
}

interface UsersQuery extends BaseQuery {
  isNFTMember?: IntBoolean
  registerStartAt?: string
  registerEndAt?: string
  sort?: SortOrder
  sortKey?: UserSortKey
  verifiedStatus?: VerifiedStatus
  keyword?: string
}
interface UsersReviewQuery extends BaseQuery {
  verifiedType?: VerifiedType
  keyword?: string
}

export type UserIdOption = { userId: string };
export type GetUserRequestOptions = RequestOptions & { userId: string };
export type GetUsersRequestOptions = RequestOptions<UsersQuery>;
export type CreateUserRequestOptions = RequestOptions<any, PartialUser>;
export type UpdateUserRequestOptions = CreateUserRequestOptions & { userId: string };
export type DeleteUserRequestOptions = UserIdOption;

export type UserResponse = Response<User>;
export type UsersResponse = Response<User[]>;
// #endregion

// #region UserReview
export type GetUsersReviewRequestOptions = RequestOptions<UsersReviewQuery>;
export type UpdateUserReviewRequestOptions = { userId: string } & RequestOptions<any, PartialVerityUserReview>;

export type UsersReviewResponse = Response<UserReview[]>;

// #endregion

// #region Admin
interface AdminsQuery extends BaseQuery {
  keyword?: string
}

export type AdminIdOption = { adminId: string };
export type GetAdminRequestOptions = RequestOptions & AdminIdOption;
export type GetAdminsRequestOptions = RequestOptions<AdminsQuery>;

export interface CreateAdminData {
  username: string
  password: string
  name: string
  phone: string
  status: AdminStatus
  roleIds: number[]
}

export type CreateAdminRequestOptions = RequestOptions<any, CreateAdminData>;
export type UpdateAdminRequestOptions = CreateAdminRequestOptions & AdminIdOption;
export type DeleteAdminRequestOptions = AdminIdOption;
export type ResetAdmin2FARequestOptions = AdminIdOption;
export type EnableAdminRequestOptions = AdminIdOption;
export type DisableAdminRequestOptions = AdminIdOption;

export type AdminResponse = Response<Admin>;
export type AdminsResponse = Response<Admin[]>;

// #endregion

// #region Role
type RolesQuery = BaseQuery;

export type RoleIdOption = { roleId: string };

export type GetRoleRequestOptions = RequestOptions & RoleIdOption;
export type GetRolesRequestOptions = RequestOptions<RolesQuery>;
export type CreateRoleRequestOptions = RequestOptions<any, PartialRole>;
export type UpdateRoleRequestOptions = CreateRoleRequestOptions & RoleIdOption;
export type DeleteRoleRequestOptions = RoleIdOption;

export type RoleResponse = Response<Role>;
export type RolesResponse = Response<Role[]>;
// #endregion

// #region Permissions

export type PermissionsResponse = Response<Permission>;
// #endregion

// #region File
interface FileData {
  /** 原始檔名  */
  filename: string
  /** 類型 */
  type: string
  /** 分塊號 */
  block_id: number
  /** 分塊總數 */
  block_tot: number
  /** 分塊檔 */
  block: Blob
  /** 檔案所在目錄 */
  dir: string
  /** 原始檔名 */
  oriname: string
  /** 副檔名 */
  ext: string
  /** 起始檔案分塊大小 */
  start: number
  /* 存檔名 */
  uuid: string
}
export type UploadFileRequestOptions = RequestOptions<any, FileData>

export interface FileResponse {
  block_id: string
  block_tot: number // 全部上傳完成，返回 block_tot
  datetime: number
  uuid: string
  source?: string
  start: number
  path?: string
  time: number
  temp_file_count: number
}
// #endregion

type ChannelsQuery = BaseQuery;
type ChannelCampaignsQuery = ChannelsQuery

export type ChannelIdOption = { channelId: string };
export type GetChannelsRequestOptions = RequestOptions<ChannelsQuery>;
export type GetChannelCampaignsRequestOptions = RequestOptions<ChannelCampaignsQuery> & ChannelIdOption;

export type ChannelsResponse = Response<Channel[]>;
export type ChannelCampaignsResponse = Response<Campaign[]>;

interface CampaignCommissionsQuery extends BaseQuery {
  channelIds?: number[]
}

interface CampaignCommissionOverviewQuery {
  channelIds?: number[]
};

export type GetCampaignCommissionsRequestOptions = RequestOptions<CampaignCommissionsQuery>;
export type GetCampaignCommissionOverviewRequestOptions = RequestOptions<CampaignCommissionOverviewQuery>;

export type CampaignCommissionsResponse = Response<CampaignCommission[]>;

export enum WithdrawalSortKey {
  CREATED_AT = 'createdAt',
  STATUS = 'status'
}

interface WithdrawalsQuery extends BaseQuery {
  sort?: SortOrder
  sortKey?: WithdrawalSortKey
}

export type GetWithdrawalsRequestOptions = RequestOptions<WithdrawalsQuery>;
export type CreateWithdrawalRequestOptions = RequestOptions<any, PartialWithdrawal>;

export type WithdrawalsResponse = Response<Withdrawal[]>;
export type WithdrawalOverviewResponse = Response<WithdrawalOverview>;

// Payee
export type UpdatePayeeRequestOptions = RequestOptions<any, PartialPayee>;
export type PayeeResponse = Response<Payee>;

export enum DailyCampaignStatisticsSortKey {
CREATED_AT = 'createdAt',
DATE = 'date',
NEXT_DAY_RETENTION_COUNT = 'nextDayRetentionCount',
WEEK_RETENTION_COUNT = 'weekRetentionCount',
REGISTRATION_COUNT = 'registrationCount',
SUBSCRIPTION_COUNT = 'subscriptionCount',
SUBSCRIPTION_AMOUNT = 'subscriptionAmount',
RENEWAL_COUNT = 'renewalCount',
RENEWAL_AMOUNT = 'renewalAmount',
ANDROID_BROWSER_VISIT_COUNT = 'androidBrowserVisitCount',
ANDROID_APP_VISIT_COUNT = 'androidAppVisitCount',
IOS_BROWSER_VISIT_COUNT = 'iosBrowserVisitCount',
IOS_APP_VISIT_COUNT = 'iosAppVisitCount',
PC_BROWSER_VISIT_COUNT = 'pcBrowserVisitCount'
}

interface DailyCampaignStatisticsQuery extends BaseQuery {
  startDate?: string
  endDate?: string
  channelIds?: number[]
  sort?: SortOrder
  sortKey?: DailyCampaignStatisticsSortKey
}

export type GetDailyCampaignStatisticsRequestOptions = RequestOptions<DailyCampaignStatisticsQuery>;
export type ExportDailyCampaignStatisticsRequestOptions = RequestOptions<DailyCampaignStatisticsQuery>;

export type DailyCampaignStatisticsResponse = Response<DailyCampaign[]>;

type PlansQuery = BaseQuery;
export type GetPlansRequestOptions = RequestOptions<PlansQuery>;
export type PlansResponse = Response<Plan[]>;
